import React from 'react';
import PageHeader from '../PageComponents/PageHeader';
import {Link} from 'gatsby';
let gravURL = process.env.REACT_APP_GRAV_URL;
export default class RecipeCollection extends React.Component {
	componentDidMount(){
		window.scrollTo(0,0)
	}
	render() {
		const collection = this.props.pageContext.pages.map((item, key)=>
			<React.Fragment>
			{item.image&&
			<div className="xs:w-6/12 md:w-4/12 lg:w-3/12 px-2 mb-4">
				<div className="mealTileWrapper mb-4">
					<Link to={item.url}><img src={gravURL + item.image} alt={item.title}/></Link>
					<h5 className="recipeBookTitle font-medium text-center text-secondary">{item.title}</h5>
				</div>
			</div>
			}
			</React.Fragment>
		)
		return (
			<main>
				<PageHeader mainHeading="Recipe Collection" subHeading={this.props.pageContext.title}/>
				<section>
					<div className="pageGrid">
						<div className="flex flex-wrap">{collection}</div>
					</div>
				</section>
			</main>
		);
	}
}
